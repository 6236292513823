import React from 'react';
import { withPrefix } from 'gatsby';
import Image from "gatsby-image";


export default function Footer({ data }) {
  return (
    <div 
      // style={{ 
      //   backgroundImage: `url(${withPrefix('/images/footer-background.jpg')})`,
      //   backgroundPosition:"center right",
      //   position: "relative",
      //   height: "fit-content",
      // }}
      className="bg-right-bottom bg-white"
    > 

      {/* <div id="footer-overlay" className="absolute inset-0 -mt-px z-10"/> */}

      <div className="relative flex flex-col items-center justify-center py-20 px-8 space-y-20 z-20 h-full">

        <div className="flex flex-col md:flex-row space-y-12 md:space-y-0 md:space-x-20">

          <Image
            fluid={data.logos.vinhos.childImageSharp.fluid}
            imgStyle={{ maxWidth: "200px", height:"auto", position:"static", objectFit: "contain"}}
          />

          <Image
            fluid={data.logos.wines.childImageSharp.fluid}
            imgStyle={{ maxWidth: "200px", height:"auto", position:"static", objectFit: "contain"}}
          />

        </div>

        <p className="text-center font-sans text-base text-black">{data.terms_conditions}</p>

      </div>

    </div>
  );
};

