import React from "react"
import { withPrefix } from "gatsby"
import Image from "gatsby-image"

export default function Content({ data, buttonText, buyUrl }) {
  return (
    <div
      style={{
        backgroundImage: `url(${withPrefix("/images/content-background.jpg")})`,
        position: "relative",
        minHeight: "fit-content",
      }}
      className="bg-center bg-cover bg-opacity-20 -mt-px"
    >
      <div id="content-overlay-1" className="absolute inset-0 z-20" />
      <div id="content-overlay-2" className="absolute inset-0 z-10" />

      <div className="relative flex flex-col items-center py-28 md:py-20 lg:py-28 z-40 px-8 md:px-20 lg:px-32">
        <h1 className="font-serif text-center text-4xl md:text-4xl lg:text-5xl text-white underline mb-12 md:mb-20">
          {data.whites.title}
        </h1>

        <div className="flex max-w-6xl w-full justify-center mb-12 flex-wrap xl:flex-nowrap xl:flex-row sm:flex-col lg:flex gap-4">
          {data.whites.wines.map(wine => (
            <div
              key={wine.name}
              className="flex items-center w-full"
            >
              <Image
                fluid={wine.image.childImageSharp.fluid}
                imgStyle={{
                  position: "static",
                  objectFit: "contain",
                  width: "auto",
                }}
                className="w-1/3 h-64 pr-5 gatsby-image-wrapper"
              />
              <div className="flex flex-col w-4/5">
                <h1 className="text-white text-start font-serif text-1xl md:text-lg pb-2">
                  {wine.name}
                </h1>
                <p className="text-white font-sans text-small">
                  {wine.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        <h1 className="font-serif text-center text-4xl md:text-5xl text-white underline mb-12 md:mb-20">
          {data.reds.title}
        </h1>

        <div className="flex max-w-6xl w-full justify-center mb-12 flex-wrap xl:flex-nowrap xl:flex-row sm:flex-col lg:flex gap-4">
          {data.reds.wines.map(wine => (
            <div
              key={wine.name}
              className="flex items-center w-full"
            >
              <Image
                fluid={wine.image.childImageSharp.fluid}
                imgStyle={{
                  position: "static",
                  objectFit: "contain",
                  width: "auto",
                }}
                className="w-1/3 h-64 pr-5 gatsby-image-wrapper"
              />
              <div className="flex flex-col w-4/5 items-base">
                <h1 className="text-white text-start font-serif text-1xl md:text-lg pb-2">
                  {wine.name}
                </h1>
                <p className="text-white font-sans text-small md:text-xs lg:text-base">
                  {wine.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="px-8 md:px-20 lg:px-32 flex flex-col justify-center items-center space-y-10">
          <a
            href={buyUrl}
            className="px-3 py-2 bg-white text-primary text-2xl hover:bg-primary hover:text-white"
          >
            {buttonText}
          </a>

          <p className="text-white text-center">{data.disclaimer}</p>
        </div>
      </div>
    </div>
  )
}
