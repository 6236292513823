import React from 'react'
import { withPrefix } from 'gatsby'
import Image from "gatsby-image"

export default function About({ data }) {
  return (
    <div 
      style={{ 
        backgroundImage: `url(${withPrefix('/images/about-background.jpg')})`,
        position: "relative",
        height: "fit-content"
      }}
      className="bg-center bg-cover bg-opacity-20 -mt-px"
      id="about"
    > 

      <div id="about-overlay-1" className="absolute inset-0 z-10"/>
      <div id="about-overlay-2" className="absolute inset-0 z-20"/>

      <div className="relative px-8 py-20 md:px-20 lg:p-32 z-20">

        <h1 className="font-serif text-center text-4xl md:text-5xl text-primary underline mb-20">{data.title}</h1>
        
        <div className="space-y-4">
          {data.text.map((paragraph, i) => (
            <p key={i} className="text-base md:text-lg text-black">{paragraph}</p>
          ))}
        </div>

      </div>
      
    </div>
  );
};

