import React from 'react'
import Image from "gatsby-image"

export default function RatingsBanner({ data }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 space-y-20 md:space-y-0 w-full px-8 md:px-20 lg:px-32 justify-items-center py-20 mx-auto">
      
      <Image
        fluid={data.rating.childImageSharp.fluid}
        imgStyle={{ position:"static", objectFit: "contain", width: "auto"}}
        className="h-40 md:h-32 lg:h-40"
      />

      <Image
        fluid={data.deliver.childImageSharp.fluid}
        imgStyle={{ position:"static", objectFit: "contain", width: "auto"}}
        className="h-40 md:h-32 lg:h-40"
      />

      <Image
        fluid={data.best.childImageSharp.fluid}
        imgStyle={{ position:"static", objectFit: "contain", width: "auto"}}
        className="h-40 md:h-32 lg:h-40"
      />
      
    </div>
  );
};

