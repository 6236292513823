import React , { useState, useRef, useEffect }from 'react';
import { withPrefix } from 'gatsby';

// Icon imports
import { ReactComponent as Logo } from '../../static/images/logo.svg';
import { IoMenuSharp } from 'react-icons/io5';
import { IoCloseSharp } from 'react-icons/io5';
import { IoCaretDownSharp } from 'react-icons/io5';
import { IoCaretUpSharp } from 'react-icons/io5';

export default function Navbar({ data }) {

  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);

  const dropdownRef = useRef();

  const handleDropdown = (event) => {
    if(!dropdownRef.current.contains(event.target)){
      setIsDropdownExpanded(false);
    }
  }

  const handleFlag = () => {
    switch(data.locale) {
      case 'nl':
        return "🇳🇱";
      case 'en':
        return "🇬🇧";
      case 'pt': 
        return "🇵🇹";
      default:
        return "🇳🇱"
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleDropdown);

    return() => document.removeEventListener("mousedown", handleDropdown);
  })
  
  return (
      <nav className="fixed top-0 w-full z-50 flex justify-between flex-wrap items-center px-8 py-5 md:px-20 lg:px-32 md:py-6 bg-black shadow-md transition-all">

        <div className="w-32">
          <Logo />
        </div>

        {isNavExpanded ? (
            <button className="ml-auto md:hidden" onClick={() => setIsNavExpanded(false)  }>
              <IoCloseSharp color="white" size="30px"/>
            </button>
          ) : (
            <button className="ml-auto md:hidden" onClick={() => setIsNavExpanded(true)}  >
              <IoMenuSharp color="white" size="30px"/>
            </button>
          )
        }
        
        <div className={`${isNavExpanded ? "flex flex-col w-full py-8 bg-black justify-center items-center space-y-5" : "hidden"} md:flex md:items-center md:space-x-6`}>

          <a 
            href="#about" 
            className="text-primary text-lg hover:text-white transition"
            onClick={() => isNavExpanded && setIsNavExpanded(false)}
          >
            {data.about}
          </a>

          <a href={data.buyUrl} className="px-3 py-2 bg-primary text-white text-lg hover:bg-white hover:text-primary transition">{data.buttonText}</a>

          <div ref={dropdownRef}>

            <button 
              className="px-4 py-2 w-24 flex items-center justify-between border border-white"
              onClick={() => setIsDropdownExpanded(!isDropdownExpanded)}
              id="menu-btn"
            >

              <span className="text-2xl">{handleFlag()}</span>
              {isDropdownExpanded ? (
                  <IoCaretUpSharp color="white" size="20px" />
                ) : (
                  <IoCaretDownSharp color="white" size="20px" />
                )
              }

            </button>

            <div 
              className={`
                ${isDropdownExpanded ? 
                  "absolute left flex flex-col mt-1 p-2 bg-black text-2xl w-24" 
                  : 
                  "hidden"
                } `}
            >
              {data.locale !== 'nl' && (
                <a href="/nl" className="px-2 py-1 hover:bg-white">🇳🇱</a>
              )}
              {data.locale !== 'en' && (
                <a href="/" className="px-2 py-1 hover:bg-white">🇬🇧</a>
              )}
              {data.locale !== 'pt' && (
                <a href="/pt" className="px-2 py-1 hover:bg-white">🇵🇹</a>
              )}

            </div>

          </div>
        
        </div>

      </nav>
  );
};
