import React from "react"
import { withPrefix } from "gatsby"
import Image from "gatsby-image"

export default function Hero({ data, locale }) {
  return (
    <div
      style={{
        backgroundImage: `url(${withPrefix("/images/BG_New.jpg")})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "relative",
        heigth: "fit-content",
      }}
      className="bg-right-bottom"
    >
      <div id="hero-overlay-2" className="absolute inset-0 z-20 " />

      <div className="relative overflow-hidden flex flex-col md:flex-row py-28 lg:py-40 z-40 px-8 space-y-12 md:px-20 lg:px-32 items-center">
        <div className="font-serif w-full md:w-7/12 h-full items-start md:items-center">
          <h1 className="text-3xl md:text-4xl lg:text-6xl text-white py-2 md:py-2 lg:my-2">
            {data.title}
          </h1>

          <h4 className="text-white text-base  md:text-md">
            {data.text}
          </h4>

          <h2 className="text-white italic text-base md:text-lg lg:text-3xl">
            {data.curator}
          </h2>

          <h5 className="text-secondary text-base md:text-xl italic">
            {data.curatorTitle}
          </h5>

          <div className="flex space-x-8 mt-12">
          <Image
            fluid={data.ronald.childImageSharp.fluid}
            imgStyle={{
              position: "static",
              objectFit: "contain",
              width: "auto",
            }}
            className="h-44 mb-6 md:w-40 lg:w-52 xl:w-auto xl:h-44"
          />

          <Image
            fluid={data.rodolfo.childImageSharp.fluid}
            imgStyle={{
              position: "static",
              objectFit: "contain",
              width: "auto",
            }}
            className="h-44 md:w-40 lg:w-52 xl:w-auto xl:h-44"
          />
        </div>

        </div>

        

        <div className="relative flex flex-col -space-y-4 -lg:space-y-40 items-end lg:items-end sm:ml-auto">
          <div className="relative">
            <Image
              fluid={data.image.childImageSharp.fluid}
              imgStyle={{
                position: "static",
                objectFit: "contain",
                width: "auto",
              }}
              className="w-72 w- md:w-64 lg:w-80 xl:w-600"
            />
            {locale === 'nl' && (
              <div className="absolute top-4 -left-8 md:top-2 md:-left-12 xl:top-8 xl:-right-8 p-4 mt-4 ml-auto rounded-full bg-primary w-32 h-32 xl:w-52 xl:h-52 shadow-lg flex flex-col items-center justify-center">
                <div className="text-white font-serif text-2xl xl:text-2xl text-center">Beperkt tot de eerste 100 bestellingen </div>
              </div>
            )}
            {locale === 'pt' && (
              <div className="absolute top-4 -left-8 md:top-2 md:-left-12 xl:top-8 xl:-right-8 p-4 mt-4 ml-auto rounded-full bg-primary w-32 h-32 xl:w-52 xl:h-52 shadow-lg flex flex-col items-center justify-center">
                <div className="text-white font-serif text-2xl xl:text-2xl text-center">Limitado às primeiras 100 encomendas</div>
              </div>
            )}

            {locale === 'en' && (
              <div className="absolute top-4 -left-8 md:top-2 md:-left-12 xl:top-8 xl:-right-8 p-4 mt-4 ml-auto rounded-full bg-primary w-32 h-32 xl:w-52 xl:h-52 shadow-lg flex flex-col items-center justify-center">
                <div className="text-white font-serif text-2xl xl:text-2xl text-center">Limited to the first 100 orders</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
