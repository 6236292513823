import React from "react"
import { withPrefix } from "gatsby"
import Image from "gatsby-image"

export default function Book({ data }) {
  return (
    <div
      style={{
        backgroundImage: `url(${withPrefix('/images/book_bg.jpg')})`,
        position: "relative",
        height: "fit-content",
      }}
      className="bg-center bg-cover -mt-px"
      id="book"
    >
      <div id="book-overlay-1" className="absolute inset-0 z-10" />
    
      <div className="relative px-8 py-20 md:px-20 lg:p-32 z-20">
        <h1 className="font-serif text-center text-4xl md:text-5xl text-neutral underline mb-20">
          {data.title}
        </h1>

        <div className="space-y-4">
          {data.text.map((paragraph, i) => (
            <p key={i} className="text-base md:text-lg text-black">
              {paragraph}
            </p>
          ))}
        </div>

        <div className="space-y-32 py-20">
          {data.quotes.map((quotes, q) => (
            <p key={q} className="text-base italic md:text-3xl text-primary mb-12">
              {quotes}
            </p>
          ))}
          </div>
      </div>
    </div>
  )
}
