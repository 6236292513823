import React from "react";

// Component imports
import SEO from "./SEO";
import Navbar from "./Navbar";
import Footer from "./Footer";

// Packages imports
import "@fontsource/dm-serif-display";
import "@fontsource/public-sans";

export default function Layout ({ children, navbarData ,footerData }) {

  return (
    <>
      <SEO />
      <Navbar data={navbarData}/>
        {children}
      <Footer data={footerData}/>
    </>
  );
};
