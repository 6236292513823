import React from 'react';
import Image from "gatsby-image";

export default function AwardsBanner({ data }) {
  return (
    <div className="bg-black py-20 mx-auto">
    
      <Image
        fluid={data.title.childImageSharp.fluid}
        imgStyle={{ position:"static", objectFit: "contain", height: "auto"}}
        className="w-40 mx-auto mb-10"
      />

      <div className="grid grid-cols-1 md:grid-cols-3 w-full px-8 md:px-20 lg:px-32 justify-items-center space-y-8 md:space-y-0">

        <Image
          fluid={data.badge1.childImageSharp.fluid}
          imgStyle={{ position:"static", objectFit: "contain", height: "auto"}}
          className="w-40"
        />

        <Image
          fluid={data.badge2.childImageSharp.fluid}
          imgStyle={{ position:"static", objectFit: "contain", height: "auto"}}
          className="w-40"
        />

        <Image
          fluid={data.badge3.childImageSharp.fluid}
          imgStyle={{ position:"static", objectFit: "contain", height: "auto"}}
          className="w-40"
        />

      </div>
    </div>
  );
};

